import TYPES from '@/types';
import Vue from 'vue';
import { v4 } from 'uuid';

// Application
import GetRiskToleranceQuestionsQuery
  from '@/modules/flagship/risk-tolerance-questions/application/queries/get-risk-tolerance-questions-query';
import AccessMoreStrategiesStateManagerService
  from '@/modules/my-investment/allianz/access-more-strategies/application/services/access-more-strategies-state-manager-service';
import CreateInvestmentTypeHiringStepCommand
  from '@/modules/my-investment/allianz/investment-type-hiring-step/application/commands/create-investment-type-hiring-step-command';
import UpdateInvestmentTypeHiringStepCommand
  from '@/modules/my-investment/allianz/investment-type-hiring-step/application/commands/update-investment-type-hiring-step-command';
import UpdateInvestorGoalRiskToleranceCommand
  from '@/modules/flagship/investor-goal-risk-tolerance/application/commands/update-investor-goal-risk-tolerance-command';
import NotifyInvestorGoalRiskToleranceCommand
  from '@/modules/flagship/investor-goal-risk-tolerance/application/commands/notify-investor-goal-risk-tolerance-command';

// Domain
import { RiskToleranceQuestionsDto }
  from '@/modules/flagship/risk-tolerance-questions/domain/dtos/risk-tolerance-questions-dto';
import { Answer, RiskToleranceQuestionsEntity }
  from '@/modules/flagship/risk-tolerance-questions/domain/entities/risk-tolerance-questions-entity';
import {
  LowToleranceNotificationDto,
} from '@/modules/flagship/investor-goal-risk-tolerance/domain/dto/low-tolerance-notification-dto';
import AccessMoreStrategiesState from '@/modules/my-investment/allianz/access-more-strategies/domain/state/access-more-strategies-state';
import Inject from '@/modules/shared/domain/di/inject';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class AccessMoreStrategiesRiskToleranceViewModel {
  @Inject(TYPES.GET_RISK_TOLERANCE_QUESTIONS_QUERY)
  private readonly get_risk_tolerance_questions_query!: GetRiskToleranceQuestionsQuery;

  @Inject(TYPES.ACCESS_MORE_STRATEGIES_STATE_MANAGER_SERVICE)
  private readonly manager_service!: AccessMoreStrategiesStateManagerService;

  @Inject(TYPES.CREATE_INVESTMENT_TYPE_HIRING_STEP_COMMAND)
  private readonly create_investment_type_hiring_step!: CreateInvestmentTypeHiringStepCommand;

  @Inject(TYPES.UPDATE_INVESTMENT_TYPE_HIRING_STEP__COMMAND)
  private readonly update_investment_type_hiring_step!: UpdateInvestmentTypeHiringStepCommand;

  @Inject(TYPES.UPDATE_INVESTOR_GOAL_RISK_TOLERANCE_COMMAND)
  private readonly update_investor_goal_risk_tolerance_cmd!: UpdateInvestorGoalRiskToleranceCommand;

  @Inject(TYPES.NOTIFY_INVESTOR_GOAL_RISK_TOLERANCE_COMMAND)
  private readonly notify_investor_goal_risk_tolerance_cmd!: NotifyInvestorGoalRiskToleranceCommand;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  readonly view: Vue;

  readonly i18n_namespace = 'components.strategy-moderate-wealth.strategy-risk-tolerance';

  private readonly customer_id = sessionStorage.getItem('user_id');

  private readonly WARNING_ANSWERS_NAMES = ['5_percent_annualized_return', 'sell_immediately_and_never_invest_in_that_strategy_again', 'highly_likely']

  private readonly step_name = 'more_strategies_risk_tolerance';

  questions: Array<RiskToleranceQuestionsEntity> = []

  is_invalid_form = false;

  step_id = v4();

  selected_answers: Array<Answer> = [];

  state: AccessMoreStrategiesState;

  public constructor(view: Vue) {
    this.view = view;
    this.state = this.manager_service.getAccessMoreStrategiesState();
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  nextStep = async () => {
    if (this.state.user_selected_a_warning_answer) {
      await this.updateRiskToleranceAnswers();
    }
    this.view.$emit('nextStep', {
      answers_dto: this.state.answers_dto,
      user_selected_a_warning_answer: this.state.user_selected_a_warning_answer,
    });
  }

  loadRiskToleranceQuestions = async () => {
    try {
      const payload: RiskToleranceQuestionsDto = {
        investment_product_id: this
          .state.strategy.id,
      };
      const result = await this.get_risk_tolerance_questions_query.execute(payload);
      this.questions = result.map((question) => ({
        ...question,
        label: question.label
          .replace('para tu meta “Nombre de meta”', 'en tu inversion')
          .replace('vincular tu meta “Nombre meta” a la estrategia SOWOS Moderado',
            'invertir en una estrategia con mayor riesgo')
          .replace('de esta meta', '')
          .replace('antes de cumplir el objetivo', 'antes del plazo de inversión'),
      }));
      this.loadRistToleranceAnswers();
    } catch {
      this.message_notifier.showErrorNotification(this.translate('load_risk_tolerance_questions'));
    }
  }

  loadRistToleranceAnswers = () => {
    if (this.state.answers_dto.length > 0) {
      this.questions.forEach((question) => {
        question.answers.forEach((answer) => {
          this.state.answers_dto.forEach((answer_dto) => {
            if (question.id === answer_dto.risk_tolerance_question_id) {
              if (answer.id === answer_dto.risk_tolerance_answer_id) {
                this.selected_answers.push(answer);
              }
            }
          });
        });
      });
    }
  }

  notifyUserChoseAWarningAnswer = async () => {
    try {
      const payload: LowToleranceNotificationDto = {
        customer_id: this.customer_id || '',
        investor_goal_id: null,
        investment_product_fund_type_id: this.state.strategy.id,
      };
      await this.notify_investor_goal_risk_tolerance_cmd.execute(payload);
    } catch {
      this.message_notifier.showErrorNotification('errors.notify_investor_goal_risk_tolerance');
    }
  }

  updateRiskToleranceAnswers = async () => {
    try {
      const investor_goal_risk_tolerance_dto = {
        customer_id: this.customer_id || '',
        investor_goal_id: null,
        investment_product_fund_type_id: this.state.strategy.id,
        answers: this.state.answers_dto,
      };
      await this.update_investor_goal_risk_tolerance_cmd.execute(investor_goal_risk_tolerance_dto);
      if (this.state.user_selected_a_warning_answer) {
        await this.notifyUserChoseAWarningAnswer();
      }
    } catch {
      this.message_notifier.showErrorNotification('errors.update_risk_tolerance_answers');
    }
  }

  saveAnswers = async () => {
    try {
      if (this.state.risk_tolerance_step_id !== '') {
        const update_step = {
          id: this.state.risk_tolerance_step_id,
          current_step: this.step_name,
          payload: {
            answers_dto: this.state.answers_dto,
          },
        };
        await this.update_investment_type_hiring_step.execute(update_step);
      } else {
        this.state.risk_tolerance_step_id = this.step_id;
        const new_step = {
          id: this.state.risk_tolerance_step_id,
          current_step: this.step_name,
          payload: {
            answers_dto: this.state.answers_dto,
          },
        };
        await this.create_investment_type_hiring_step.execute(new_step);
      }
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.save_answers'));
    }
  }

  verifyIfWarningChangeStrategyViewHasToBeShowed = async () => {
    const user_selected_a_warning_answer = this.selected_answers
      .some((answer) => this.WARNING_ANSWERS_NAMES.includes(answer.name));
    this.state.user_selected_a_warning_answer = user_selected_a_warning_answer;
    if (!this.state.user_selected_a_warning_answer) {
      this.nextStep();
    } else {
      await this.saveAnswers();
    }
  }

  updateSelectedAnswer = (answer: Answer) => {
    const question_stored = this.state.answers_dto.find((answer_dto) => (
      answer_dto.risk_tolerance_question_id === answer.risk_tolerance_question_id
    ));
    if (question_stored) {
      question_stored.risk_tolerance_answer_id = answer.id;
    } else {
      this.state.answers_dto.push({
        id: v4(),
        risk_tolerance_question_id: answer.risk_tolerance_question_id,
        risk_tolerance_answer_id: answer.id,
      });
    }
  }

  noChangeStrategy = () => {
    this.state.user_selected_a_warning_answer = false;
    this.prevStep();
  }

  initialize = async () => {
    this.state.is_loading = true;
    await this.loadRiskToleranceQuestions();
    this.state.is_loading = false;
  }
}
