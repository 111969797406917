


































































































import { Component, Vue } from 'vue-property-decorator';
import AccessMoreStrategiesRiskToleranceViewModel
  from
  '@/vue-app/view-models/allianz-dashboard/access-more-strategies/access-more-strategies-risk-tolerance-view-model';

@Component({
  name: 'AccessMoreStrategiesRiskTolerance',
  components: {
    StrategyModerateWarningChangeStrategy: () => import('@/vue-app/components/strategy-moderate-wealth/hiring-flow/StrategyModerateWarningChangeStrategy.vue'),
  },
})
export default class AccessMoreStrategiesRiskTolerance extends Vue {
  strategy_risk_tolerance_view_model = Vue.observable(
    new AccessMoreStrategiesRiskToleranceViewModel(this),
  );

  async created() {
    await this.strategy_risk_tolerance_view_model
      .initialize();
  }
}
